.cc-window {
     opacity: 1;
     transition: opacity 1s ease;
}
.cc-window.cc-invisible {
     opacity: 0;
}
.cc-animate.cc-revoke {
     transition: transform 1s ease;
}
.cc-animate.cc-revoke.cc-top {
     transform: translateY(-2em);
}
.cc-animate.cc-revoke.cc-bottom {
     transform: translateY(2em);
}
.cc-animate.cc-revoke.cc-active.cc-bottom,
.cc-animate.cc-revoke.cc-active.cc-top,
.cc-revoke:hover {
     transform: translateY(0);
}
.cc-grower {
     max-height: 0;
     overflow: hidden;
     transition: max-height 1s;
}
.cc-link,
.cc-revoke:hover {
     text-decoration: underline;
}
.cc-revoke,
.cc-window {
     position: fixed;
     overflow: hidden;
     box-sizing: border-box;
     font-family: Helvetica, Calibri, Arial, sans-serif;
     font-size: 16px;
     line-height: 1.5em;
     display: flex;
     flex-wrap: nowrap;
     z-index: 9999;
}
.cc-window.cc-static {
     position: static;
}
.cc-window.cc-floating {
     padding: 2em;
     max-width: 24em;
     flex-direction: column;
}
.cc-window.cc-banner {
     padding: 1em 1.8em;
     width: 100%;
     flex-direction: row;
}
.cc-revoke {
     padding: 0.5em;
}
.cc-header {
     font-size: 18px;
     font-weight: 700;
}
.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
     cursor: pointer;
}
.cc-link {
     opacity: 0.8;
     display: inline-block;
     padding: 0.2em;
}
.cc-link:hover {
     opacity: 1;
}
.cc-link:active,
.cc-link:visited {
     color: initial;
}
.cc-btn {
     display: block;
     padding: 0.4em 0.8em;
     font-size: 0.9em;
     font-weight: 700;
     border-width: 2px;
     border-style: solid;
     text-align: center;
     white-space: nowrap;
}
.cc-banner .cc-btn:last-child {
     min-width: 140px;
}
.cc-highlight .cc-btn:first-child {
     background-color: transparent;
     border-color: transparent;
}
.cc-highlight .cc-btn:first-child:focus,
.cc-highlight .cc-btn:first-child:hover {
     background-color: transparent;
     text-decoration: underline;
}
.cc-close {
     display: block;
     position: absolute;
     top: 0.5em;
     right: 0.5em;
     font-size: 1.6em;
     opacity: 0.9;
     line-height: 0.75;
}
.cc-close:focus,
.cc-close:hover {
     opacity: 1;
}
.cc-revoke.cc-top {
     top: 0;
     left: 3em;
     border-bottom-left-radius: 0.5em;
     border-bottom-right-radius: 0.5em;
}
.cc-revoke.cc-bottom {
     bottom: 0;
     left: 3em;
     border-top-left-radius: 0.5em;
     border-top-right-radius: 0.5em;
}
.cc-revoke.cc-left {
     left: 3em;
     right: unset;
}
.cc-revoke.cc-right {
     right: 3em;
     left: unset;
}
.cc-top {
     top: 1em;
}
.cc-left {
     left: 1em;
}
.cc-right {
     right: 1em;
}
.cc-bottom {
     bottom: 1em;
}
.cc-floating > .cc-link {
     margin-bottom: 1em;
}
.cc-floating .cc-message {
     display: block;
     margin-bottom: 1em;
}
.cc-window.cc-floating .cc-compliance {
     flex: 1 0 auto;
}
.cc-window.cc-banner {
     align-items: center;
}
.cc-banner.cc-top {
     left: 0;
     right: 0;
     top: 0;
}
.cc-banner.cc-bottom {
     left: 0;
     right: 0;
     bottom: 0;
}
.cc-banner .cc-message {
     flex: 1;
}
.cc-compliance {
     display: flex;
     align-items: center;
     align-content: space-between;
}
.cc-compliance > .cc-btn {
     flex: 1;
}
.cc-btn + .cc-btn {
     margin-left: 0.5em;
}
@media print {
     .cc-revoke,
     .cc-window {
          display: none;
     }
}
@media screen and (max-width: 900px) {
     .cc-btn {
          white-space: normal;
     }
}
@media screen and (max-width: 414px) and (orientation: portrait),
     screen and (max-width: 736px) and (orientation: landscape) {
     .cc-window.cc-top {
          top: 0;
     }
     .cc-window.cc-bottom {
          bottom: 0;
     }
     .cc-window.cc-banner,
     .cc-window.cc-left,
     .cc-window.cc-right {
          left: 0;
          right: 0;
     }
     .cc-window.cc-banner {
          flex-direction: column;
     }
     .cc-window.cc-banner .cc-compliance {
          flex: 1;
     }
     .cc-window.cc-floating {
          max-width: none;
     }
     .cc-window .cc-message {
          margin-bottom: 1em;
     }
     .cc-window.cc-banner {
          align-items: unset;
     }
}
.cc-floating.cc-theme-classic {
     padding: 1.2em;
     border-radius: 5px;
}
.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
     text-align: center;
     display: inline;
     flex: none;
}
.cc-theme-classic .cc-btn {
     border-radius: 5px;
}
.cc-theme-classic .cc-btn:last-child {
     min-width: 140px;
}
.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
     display: inline-block;
}
.cc-theme-edgeless.cc-window {
     padding: 0;
}
.cc-floating.cc-theme-edgeless .cc-message {
     margin: 2em 2em 1.5em;
}
.cc-banner.cc-theme-edgeless .cc-btn {
     margin: 0;
     padding: 0.8em 1.8em;
     height: 100%;
}
.cc-banner.cc-theme-edgeless .cc-message {
     margin-left: 1em;
}
.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
     margin-left: 0;
}
ul,
ol,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
     padding-left: 0;
     list-style-type: none;
}
@media all and (max-width: 1023px) {
     ul,
     ol,
     a,
     p,
     h1,
     h2,
     h3,
     h4,
     h5,
     h6 {
          margin: 35px 0;
     }
}
* {
     box-sizing: border-box;
}
div > h1:first-child,
div > h2:first-child,
div > h3:first-child,
div > h4:first-child,
div > h5:first-child,
div > h6:first-child,
div > p:first-child {
     margin-top: 0;
}
div > h1:last-child,
div > h2:last-child,
div > h3:last-child,
div > h4:last-child,
div > h5:last-child,
div > h6:last-child,
div > p:last-child {
     margin-bottom: 0;
}
h2 {
     margin-top: 30px;
}
img {
     max-width: 100%;
     height: auto;
}
body {
     background: #f5f5f5;
     font-size: 15px;
     min-width: 320px;
     position: relative;
     line-height: 22px;
     font-family: Arial, "Oxygen", sans-serif, "Helvetica Neue", sans-serif;
     color: #545454;
     margin: 0;
     overflow-x: hidden;
}
html {
     overflow-x: hidden;
     max-width: 100%;
}
.resize {
     width: 80%;
     min-width: 1024px;
     max-width: 1600px;
     margin: 0 auto;
}
@media all and (max-width: 1023px) {
     .resize {
          width: calc(100% - 20px);
          min-width: 100%;
     }
}
header {
     background-color: #77cef2;
     color: white;
     font-weight: bold;
     font-size: 24px;
     margin-bottom: 15px;
}
@media all and (max-width: 1023px) {
     header {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 100;
     }
}
header .resize {
     display: flex;
     align-items: center;
     justify-content: left;
     height: 60px;
}
@media all and (max-width: 1023px) {
     header .resize {
          justify-content: space-between;
          padding-right: 10px;
     }
}
header .logo {
     padding: 5px;
     margin-top: 31px;
}
header .logo:before {
     content: "";
     width: 0;
     height: 0;
}
header .logo,
header .logo:before {
     display: inline-block;
     border: 15px double transparent;
     border-top-color: #2377ef;
     border-radius: 50%;
}
header .title {
     font-size: inherit;
     margin: 0;
}
header .burger {
     display: none;
     min-width: 40px;
     height: 40px;
     margin-left: 20px;
     background: #111;
     border-radius: 50%;
}
@media all and (max-width: 1023px) {
     header .burger {
          display: flex;
          align-items: center;
          justify-content: center;
     }
}
header .burger.active span {
     background: transparent;
}
header .burger.active span:before {
     transform: rotate(45deg);
     top: 0;
}
header .burger.active span:after {
     transform: rotate(-45deg);
     bottom: 0;
}
header .burger span {
     position: relative;
     display: block;
     width: 20px;
     height: 3px;
     background: #fff;
     border-radius: 10px;
     transition: all 0.3s ease;
}
header .burger span:before {
     position: absolute;
     content: "";
     width: 100%;
     height: 3px;
     top: -6px;
     left: 0;
     background: #fff;
     border-radius: 10px;
     transition: all 0.3s ease;
}
header .burger span:after {
     position: absolute;
     content: "";
     width: 100%;
     height: 3px;
     bottom: -6px;
     left: 0;
     background: #fff;
     border-radius: 10px;
     transition: all 0.3s ease;
}
@media all and (max-width: 1023px) {
     .content {
          margin-top: 75px;
     }
}
.content section {
     position: relative;
}
.content .anchor {
     position: absolute;
     top: -15px;
}
@media all and (max-width: 1023px) {
     .content .anchor {
          top: -75px;
     }
}
.content .resize {
     display: flex;
     align-items: flex-start;
     justify-content: space-between;
     flex-wrap: wrap;
}
.content h1 {
     font-size: 2em;
}
.content a {
     text-decoration: none;
}
.content a:hover {
     text-decoration: underline;
}
.box {
     box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
     padding: 30px;
     background-color: white;
     margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
     .box {
          padding: 20px;
     }
}
.box.scrollLinks {
     transition: all 0.3s ease;
}
@media all and (max-width: 1023px) {
     .box.scrollLinks {
          position: fixed;
          opacity: 0;
          visibility: hidden;
          left: 0;
          width: 100%;
          top: 0;
     }
     .box.scrollLinks.active {
          opacity: 1;
          visibility: visible;
          top: 60px;
     }
}
.button {
     display: block;
     margin: 30px auto 0;
     width: 150px;
     height: 60px;
     line-height: 60px;
     text-align: center;
     font-size: 18px;
     border-radius: 10px;
     font-weight: 700;
}
.button:hover {
     text-decoration: none !important;
}
.button.light {
     background: #8ac1f8;
     box-shadow: 0 2px 5px 1px rgba(138, 193, 248, 0.5);
     color: #333333;
}
.button.dark {
     background: #2f4069;
     box-shadow: 0 2px 5px 1px rgba(47, 64, 105, 0.5);
     color: #ffffff;
}
aside {
     width: 30%;
     color: #111;
}
@media all and (max-width: 1169px) {
     aside {
          width: 100%;
     }
}
aside .box {
     background: #e1e1e1;
}
aside .box .list:last-child {
     margin-bottom: 0;
}
aside .box .list.circle-bullet li a {
     vertical-align: middle;
}
aside .box .list.circle-bullet li a:before {
     border: none;
     transform: none;
     border-radius: 50%;
     background: #111;
     vertical-align: middle;
}
aside .box .list li a:before {
     position: relative;
     content: "";
     display: inline-block;
     width: 0.4em;
     height: 0.4em;
     border-right: 0.2em solid #111;
     border-top: 0.2em solid #111;
     transform: rotate(45deg);
     margin-right: 0.5em;
}
aside .box.toolbox {
     display: flex;
     background: #c4c4c4;
     align-items: center;
     padding: 15px;
}
aside .box.toolbox .columns {
     width: 100%;
}
aside .box.toolbox h3 {
     margin-bottom: 10px;
}
aside .box.toolbox img {
     max-width: 100px;
     margin-right: 15px;
}
aside .box.toolbox ul {
     margin: 0;
}
aside .box.toolbox p {
     margin-top: 10px;
     font-size: 13px;
     line-height: 1.2;
}
aside .toolbox-title {
     margin: 25px 0 10px;
}
aside a {
     color: #2479ff;
}
aside a:hover {
     color: #0000ee;
}
.columns {
     display: flex;
     justify-content: space-between;
     align-items: flex-start;
     flex-wrap: wrap;
     background: #f8f8f8;
     border: 1px solid #ddd;
     box-shadow: inset 0 0 5px #000;
     padding: 15px;
     font: 1rem Inconsolata, monospace;
}
.columns p {
     margin: 0;
}
hr {
     margin: 20px auto;
}
footer {
     margin-top: 30px;
     background: #c4c4c4;
     padding: 40px 0;
     color: #111;
}
footer .resize {
     display: flex;
     justify-content: space-between;
     align-items: flex-end;
}
@media all and (max-width: 1023px) {
     footer .resize {
          padding: 0 10px;
     }
}
footer .links {
     display: flex;
}
footer .links ul {
     margin: 0 20px 0 0;
}
footer .links a {
     font-size: 18px;
     text-decoration: underline;
     color: #111;
}
footer .copyright {
     font-weight: 700;
     font-size: 14px;
}
main {
     width: 68%;
}
@media all and (max-width: 1169px) {
     main {
          width: 100%;
     }
}
main .links {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     flex-wrap: wrap;
     justify-content: center;
}
@media all and (max-width: 767px) {
     main .links {
          justify-content: space-around;
     }
}
main .links .item {
     padding: 30px;
     width: calc(48% - 30px);
     border: 1px solid #333;
     background: #f6ffff;
     margin: 15px;
}
@media all and (max-width: 767px) {
     main .links .item {
          width: 100%;
     }
}
main .links .item h4 {
     font-size: 17px;
     margin-bottom: 0;
     color: #333;
}
main .links .item ul {
     margin-top: 5px;
     font-size: 16px;
     color: #333;
     font-weight: 500;
}
main .bordered {
     padding: 20px;
     border: 1px dotted #8e8e8e;
     background: #f3f3f3;
}
main .bordered h3,
main .bordered ul,
main .bordered p {
     color: #111;
     font-weight: 700;
}
main .bordered .columns {
     justify-content: flex-start;
}
main .bordered .columns p,
main .bordered .columns ul {
     margin: 5px 0 5px;
     min-width: 235px;
     font: 1rem Inconsolata, monospace;
     font-weight: 700;
     color: #007aa8;
}
main .heading {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
}
main .heading h1 {
     margin-bottom: 25px;
     line-height: 1.1;
}
@media all and (max-width: 767px) {
     main .heading h1 {
          margin-bottom: 10px;
     }
}
main .heading h2 {
     line-height: 1.1;
}
@media all and (max-width: 767px) {
     main .heading h2 {
          margin-top: 10px;
     }
}
main .heading .date {
     margin: 0 0 0 20px;
}
@media all and (max-width: 767px) {
     main .heading .date {
          width: 100%;
          margin: 10px 0 0;
     }
}
main p img.alignright {
     float: right;
     margin-left: 15px;
}
main p img.alignleft {
     float: left;
     margin-right: 15px;
}
@media all and (max-width: 767px) {
     main p img.thumbnail {
          display: block;
          float: none;
          margin: 15px auto;
     }
}
main .share {
     display: inline-flex;
     align-items: center;
     padding: 10px;
     border: 1px solid #333;
}
main .share__title {
     margin: 0;
}
@media all and (max-width: 767px) {
     main .share__title {
          font-size: 18px;
     }
}
main .socials {
     display: flex;
     align-items: center;
     margin: 0;
}
main .socials li {
     margin: 0 15px;
     max-width: 30px;
}
@media all and (max-width: 767px) {
     main .socials li {
          margin: 0 8px;
          max-width: 25px;
     }
}
main .socials li a {
     display: block;
     text-decoration: none;
     margin: 0;
}
main .socials li a svg {
     display: block;
     width: 100%;
}
.title a {
     text-decoration: none;
}
@media only screen and (max-width: 600px) {
     header .title {
          font-size: 20px;
     }
}
.content_block {
     margin-top: 30px;
}
.content_block h1,
.content_block h2,
.content_block h3 {
     margin-top: 35px;
     line-height: normal;
}
.content_block p {
     margin-top: 0px;
}
.content_block .intro-sec {
     border-left: 5px solid #153a48;
}
.content_block .list-element ol {
     margin-left: 15px;
     counter-reset: list-counter;
     list-style: none;
}
.content_block .list-element ol li {
     margin: 1.5em 0;
}
.content_block .list-element ol li p {
     display: inline;
}
.content_block .list-element ol li:before {
     content: counter(list-counter);
     counter-increment: list-counter;
     width: 1em;
     height: 1em;
     padding: 0.5em;
     margin-right: 1em;
     border: 0.25em solid #ccc;
     vertical-align: middle;
     background: #006186;
     color: #fff;
     font-family: arial;
     font-weight: bold;
     text-align: center;
     display: inline-block;
}
.content_block .list-element ul {
     margin-left: 30px;
}
.content_block .list-element ul li {
     list-style-type: square !important;
}
.content_block .list-element ul li::before {
     color: #1e4c5e;
     font-weight: bold;
     width: 2em;
}
.content_block .pic .introduction {
     border-left: 6px solid #1e4c5e;
     padding: 1em;
     background-color: #e7f8ff;
     margin-bottom: 15px;
}
.content_block .pic .pshadow {
     box-shadow: 2px 2px 4px #ccc;
     border-radius: 7px;
}
.content_block .pic .conclusion {
     margin-top: 20px;
}
.error-message {
     position: relative;
     padding: 0.75rem 1.25rem;
     margin-bottom: 1rem;
     border: 1px solid;
     border-radius: 0.25rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #721c24;
     background-color: #f8d7da;
     border-color: #f5c6cb;
}
